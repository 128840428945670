path {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

/* Override Antd */

/* Form */

.ant-form-item-label {
  display: flex !important;
  justify-content: flex-start !important;
  color: #666666 !important;
  position: absolute;
  top: -13px;
  left: 15px;
  z-index: 5;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: absolute;
  right: -5px;
}

.ant-form-item-label label {
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  padding-left: 4px;
  padding-right: 4px;
  background: white;
  height: 24px;
  text-align: center;
  background-color: #fbfbfb;
}
.ant-form-item-label label::after {
  content: none;
}
.ant-form-item {
  position: relative;
  margin-bottom: 24px !important;
}
.ant-form-item-has-error label {
  color: red;
}

/* Input */

.ant-input-affix-wrapper {
  background-color: transparent;
  border-radius: 0.75rem;
}

.ant-input {
  font-size: 1rem;
  height: 30px;
  background-color: transparent;
}

/* Pagination */

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* Button */

.ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Menu */

.ant-menu {
  background-color: #f9fafc;
}

.ant-menu-sub {
  background-color: #f9fafc !important;
}

.ant-menu-item {
  display: flex !important;
  align-items: center !important;
}

/* Modal */

.ant-modal-header {
  border-radius: 12px 12px 0 0 !important;
}

.ant-modal-content {
  border-radius: 12px !important;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}

/* Tab */

.ant-tabs-card .ant-tabs-tab {
  padding: 8px 16px !important;
}

.ant-tabs-tab {
  padding: 12px 2.5rem !important;
}

.ant-tabs-tab {
  font-size: 16px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1a1a1a;
  font-weight: bold;
}
.ant-tabs-tab:hover {
  color: #1a1a1a;
}

.ant-tabs-ink-bar {
  background: #ed1c24;
}

/* Table */

.ant-table-wrapper table {
  border: 1px solid #f0f0f0;
}

.ant-table-thead > tr > th {
  background-color: white;
  font-weight: 700;
  cursor: default;
}

.ant-table-wrapper .ant-checkbox-wrapper .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border: 3px solid black;
}

.ant-table-wrapper
  .ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner::after {
  left: 20%;
}

/* Select */

.ant-select-selection-item-remove {
  display: flex !important;
  align-items: center !important;
}
.ant-select-selector {
  height: 2.5rem !important;
  display: flex;
  align-items: center;
  background: transparent !important;
  min-width: 180px;
  border-radius: 0.75rem !important;
}
.ant-select-selection-search {
  display: flex;
  align-items: center;
}
.ant-select-selection-item {
  font-size: 16px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
}
.ant-select-selection-overflow-item > .ant-select-selection-item {
  height: 25px !important;
  border-radius: 10px !important;
}

.ant-select-disabled .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5 !important;
}

.ant-pagination {
  margin-top: 2rem !important;
  display: flex !important;
  justify-content: center !important;
}
/* Picker */
.ant-picker {
  height: 40px;
  border-radius: 0.75rem;
}
